import { AuthClient, AuthObject } from '@/types/index'
import { encryptPassword } from '@/utils/formatter'
import { AxiosPromise } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { FondasiError, useApi } from '../../useFondasiFetch'

export type LoginResponseObject = AuthObject

export type LoginFormData = {
  email: string,
  password: string,
  clientId: string,
  grantType: AuthClient
}

type Result = {
  loggedInData?: LoginResponseObject | undefined,
  loggingIn?: boolean,
  errorLogin?: FondasiError,
  login: (payload: LoginFormData, invitation?: { token: string }) => AxiosPromise<LoginResponseObject>
}

export default function useLogin(): Result {
  const { data, error, isValidating, call3 } = useApi<LoginResponseObject>()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, setToken]  = useState<string>()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }
    console.log('Can execute recaptcha')

    const token = await executeRecaptcha?.()
    setToken(token)
  }, [executeRecaptcha])

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify, error])


  return {
    loggedInData: data,
    loggingIn: isValidating,
    errorLogin: error,
    login: async (payload: LoginFormData, invitation?: { token: string }) => {
      let newToken
      if (token) {
        newToken = await executeRecaptcha?.()
      }
      return call3({
        url: '/auth/login',
        method: 'post',
        data: {
          invitation,
          ...(newToken || token) && { recaptcha: {
            token: newToken || token
          } },
          login: { ...payload, password: encryptPassword(payload.password) }
        }
      })
    }
  }
}
