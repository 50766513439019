import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import JSCookie from 'js-cookie'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useState } from 'react'
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr'
import pkg from '../../../package.json'
import FondasiAPI from '../FondasiAPI'
import FondasiAPIv3 from '../FondasiAPIv3'
import { PUSH_MESSAGE_API_URL } from '../getEnv'
// import { CHATBOT_TEMPLATING_API } from '../getEnv'

const { version } = pkg

export type FondasiError = {
  status?: number, // http response status
  error?: string | any, // error message
  code?: number // BE-defined error code
}


export type ResponseInterface<Data> = {
  data?: Data,
  error?: FondasiError | undefined,
  isValidating: boolean
}

const useFondasiFetch = <Data = any>(url: string | null, config?: AxiosRequestConfig, configSWR?: SWRConfiguration): ResponseInterface<Data>  & { revalidateFetch: (key: string | null) => void }  => {
  const { data, error, isValidating } = useSWR<any, any>(
    url,
    async (url) => {
      const res = await Axios({
        method: 'get',
        ...config,
        url: `${FondasiAPI.getBaseURL()}${config?.url || url}`,
        headers: {
          ...config?.headers,
          'Fondasi-Referrer': 'Web',
          'webapp-version': version,
          Authorization: `Bearer ${JSCookie.get('authorization')}` } })
      return res.data
    },
    {
      revalidateOnFocus: false,
      ...configSWR
    }
  )
  const { revalidateFetch } = useRevalidateFetch()

  return {
    revalidateFetch: (key: string | null) => revalidateFetch(key),
    data, error: error && { status: error?.response?.status || 500, error: getErrorMessage(error)  }, isValidating }
}

export default useFondasiFetch

export const useFondasiV3Fetch = <Data = any>(key: string | null, config?: AxiosRequestConfig, configSWR?: SWRConfiguration, useApplicationId?: boolean): ResponseInterface<Data>  & { revalidateFetch: (key: string | null) => void }  => {
  const { data, error, isValidating } = useSWR<any, any>(
    key,
    async (url) => {
      const res = await Axios({
        method: 'get',
        ...config,
        url: `${FondasiAPIv3.getBaseURL(useApplicationId)}${config?.url || url}`,
        headers: {
          ...config?.headers,
          'Fondasi-Referrer': 'Web',
          'webapp-version': version,
          Authorization: `Bearer ${JSCookie.get('authorization')}` } })
      return res.data
    },
    {
      revalidateOnFocus: false,
      ...configSWR
    }
  )
  const { revalidateFetch } = useRevalidateFetch()

  return {
    revalidateFetch: (key: string | null) => revalidateFetch(key),
    data, error: error && { status: error?.response?.status || 500, error: getErrorMessage(error)  }, isValidating }
}

interface RevalidateFetch {
  revalidateAllGroup: () => void,
  revalidateAllUserByRolesLimited: (role: string) => void,
  revalidateFetch: (key: string | null) => void
}

export const useRevalidateFetch = (): RevalidateFetch => {
  const { mutate } = useSWRConfig()
  return {
    revalidateFetch: (key: string | null): void => {
      mutate(key)
    },
    revalidateAllUserByRolesLimited: (role: string) => {
      mutate(`/users?role=${role}&limited=true`)
    },
    revalidateAllGroup:() => {
      mutate('/groups')
    }
  }
}

type ResponseUseApi<Data> = ResponseInterface<Data> & {
  setData: Dispatch<SetStateAction<Data | undefined>>,
  call: (config: AxiosRequestConfig) => AxiosPromise<Data>, // v2 api url with sync await mechanism
  call2: (config: AxiosRequestConfig) => AxiosPromise<Data>, // v2 api url with .then mechanism
  call3: (config: AxiosRequestConfig, useApplication?: boolean) => AxiosPromise<Data>, // v3 api url .then mechanism
  callFEAPI: (config: AxiosRequestConfig) => AxiosPromise<Data>, // fetch pages/api/v1
  callChatbotTemplatingAPI: (config: AxiosRequestConfig) => AxiosPromise<Data>, // fetch pages/api/v1
  callPushAPIV3: (config: AxiosRequestConfig) => AxiosPromise<Data>, // fetch v3 push api url
  reset: () => void,
  setError: Dispatch<any>
}

export const useApi = <Data = any>(): ResponseUseApi<Data> => {
  const [data, setData] = useState<Data>()
  const [error, setError] = useState<any>()
  const [isValidating, setIsValidating] = useState(false)
  const router = useRouter()

  const call = async (config: AxiosRequestConfig) => {
    setData(undefined)
    setError(undefined)
    try {
      setIsValidating(true)
      const fetch = await Axios({ baseURL: FondasiAPI.getBaseURL() || '', ...config, headers: { ...config?.headers,
        'Fondasi-Referrer': 'Web',
        'webapp-version': version,
        Authorization: `Bearer ${JSCookie.get('authorization')}` } })
      setIsValidating(false)
      if (fetch.status === 200 || fetch.status === 202) {
        setData(fetch.data)
      }
      return fetch
    } catch (error) {
      const { response } = error as any
      console.log(response)
      setError({ status: response?.status || 500, error: getErrorMessage(error)  })
      setIsValidating(false)
      return response
    }
  }

  const callAPI = (config: AxiosRequestConfig, baseUrl?: string | null) => {
    setData(undefined)
    setError(undefined)
    setIsValidating(true)
    const fetch = Axios({ baseURL: baseUrl || undefined, ...config, headers: { ...config?.headers,
      'Fondasi-Referrer': 'Web',
      'webapp-version': version,
      Authorization: `Bearer ${JSCookie.get('authorization')}` } })
    fetch
      .then(({ data }) => {
        setData(data)
      })
      .catch((error) => {
        const { response } = error as any
        console.log(response)
        setError({ status: response?.status || 500,
          error: response?.data?.error?.message || response?.data?.error || response?.data?.errors || response?.data?.msg || 'Network error',
          code: response?.data?.error?.code
        })
      })
      .finally(() => setIsValidating(false))
    return fetch
  }

  const call2 = (config: AxiosRequestConfig) => callAPI(config, FondasiAPI.getBaseURL())

  const call3 = (config: AxiosRequestConfig, useApplication?: boolean) => callAPI(config, FondasiAPIv3.getBaseURL(useApplication))

  const callFEAPI = (config: AxiosRequestConfig) => callAPI(config, '/api/v1')

  const callPushAPIV3 = (config: AxiosRequestConfig) => callAPI(config, PUSH_MESSAGE_API_URL ? `${PUSH_MESSAGE_API_URL}/v3/${router.query.application}` : FondasiAPIv3.getBaseURL(true) || '')

  // const callChatbotTemplatingAPI = (config: AxiosRequestConfig) => callAPI(config, CHATBOT_TEMPLATING_API)

  const reset = () => {
    setData(undefined)
    setError(undefined)
  }

  return { setData, data, error, isValidating, setError, call, call2, call3, callFEAPI, callChatbotTemplatingAPI: callFEAPI, reset, callPushAPIV3 }
}

export function getErrorMessage(error: any) {
  const errorData = error?.response?.data as any
  return errorData ? `${errorData?.error?.message || errorData?.msg || errorData?.body?.error?.message || errorData?.error?.msg || JSON.stringify(errorData?.error)}` : 'Network Error'
}