export function formatterValueBracket(text?: string): string {
  if (text) {
    return text.replace(/\{{2,3}.*?\}{2,3}/gi, (value) => {
      const vals = value
        .replace(/[\{\}]/gi, '')
        .trim()
        .split('.')
      return '{{{' + vals.map((attr) => `[${attr}]`).join('.') + '}}}'
    })
  }
  return ''
}

export function currencyFormatterIDR(num?: number): string {
  if (num) {
    return `IDR ${`${num}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
  }
  return 'IDR 0'
}

export function camelToSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}

export function snakeToCamelCase(str: string): string {
  return str.replace(/(_\w)/g, k => k[1].toUpperCase())
}

const isObject = function (obj: any) {
  return obj && typeof obj === 'object' && typeof obj?.getMonth !== 'function' && !Array.isArray(obj) && typeof obj !== 'function'
}


export function getDataWithSnakeCase(payload: { [key: string]: any }): { [key: string]: any } {
  return Object.keys(payload).reduce((res, key) => {
    return {
      ...res,
      [camelToSnakeCase(key)]: payload[key]
    }
  }, {})
}

export function getDataWithCamelCase(payload: { [key: string]: any }): { [key: string]: any } {
  return Object.keys(payload).reduce((res, key) => {
    return {
      ...res,
      [snakeToCamelCase(key)]: payload[key]
    }
  }, {})
}

export function getDataWithAllSnakeCase(payload: { [key: string]: any }): ((() => any) | any) {
  if (isObject(payload)) {
    return Object.keys(payload).reduce((res, key) => {
      return {
        ...res,
        [camelToSnakeCase(key)]: getDataWithSnakeCase(payload[key])
      }
    }, {})
  }else if(Array.isArray(payload)){
    return payload.map((i) => {
      return getDataWithSnakeCase(i)
    })
  }

  return payload
}

export function getDataWithAllCamelCase(payload: { [key: string]: any }): ((() => any) | any) {
  if (isObject(payload)) {
    return Object.keys(payload).reduce((res, key) => {
      return {
        ...res,
        [snakeToCamelCase(key)]: getDataWithCamelCase(payload[key])
      }
    }, {})
  }else if(Array.isArray(payload)){
    return payload.map((i) => {
      return getDataWithCamelCase(i)
    })
  }

  return payload
}

export function formatTextToSlug(text?: string): string | undefined {
  return text?.toLowerCase().replace(/ +|_/g,'-').replace(/[-]+/g, '-').replace(/[^\w-]+/g,'-')
}

export function encryptPassword(password: string) {
  let result: string | undefined

  if (typeof window === 'undefined') {
    // nodejs
    result = Buffer.from(password).toString('base64')
  } else {
    // browser
    result = window.btoa(password)
  }
  return result
}

export function formatWhatsapp(text?: string) {
  if (text) {
    const replaceItalic = text.replace(/(^|[^a-zA-Z0-9_])\_{1}(.+?)\_/g, (_match, p1, p2) => `${p1}<i>${p2}</i>`)
    const replaceBold = replaceItalic.replace(/(^|[^a-zA-Z0-9_])\*{1}(.+?)\*/g, (_match, p1, p2) => `${p1}<b>${p2}</b>`)
    const replaceStrike = replaceBold.replace(/(^|[^a-zA-Z0-9_])\~{1}(.+?)\~/g, (_match, p1, p2) => `${p1}<strike>${p2}</strike>`)
    const replaceMono = replaceStrike.replace(/(^|[^a-zA-Z0-9_])\`{3}(.+?)\`\`\`/g, (_match, p1, p2) => `${p1}<span class="font-mono">${p2}</span>`)
    return replaceMono
  }
  return ''
}

export function appendDynamicParamIfMissing(url) {
  if (!url.includes('{{1}}')) {
    // Check if the URL ends with a slash, or includes ?
    if (url.endsWith('/') || url.includes('?')) {
      url += '{{1}}'
    } else {
      url += '/{{1}}'
    }

  }
  return url
}